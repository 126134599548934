import { Globals } from './models/globals';
import { AdminService } from './services/admin.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { DataService } from './services/data.service';
import { EventsService } from './services/events.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppComponent } from './app.component';

/* установка локали RU */
import { CommonModule, registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu, 'ru_RU');
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JQ } from './models/jq';

import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';
import { AdminPanelNComponent} from './components/admin/admin-panel-n/admin-panel-n.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { AdmModalConfirmComponent } from './components/admin/adm-modal-confirm/adm-modal-confirm.component';
import { AdmModalEditComponent } from './components/admin/adm-modal-edit/adm-modal-edit.component';
import { AdmModalUploadComponent } from './components/admin/adm-modal-upload/adm-modal-upload.component';
import {
  UploadComponent,
  // UploadComponentModule
} from './components/upload/upload.component';
import {
  AdmFileuploadComponent,
  // AdmFileuploadComponentModule
} from './components/admin/adm-fileupload/adm-fileupload.component';
import { AdmModalLinkComponent } from './components/admin/adm-modal-link/adm-modal-link.component';
import { BlockLinkSubscribeComponent } from './components/templates-common/block-link-subscribe/block-link-subscribe.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { AdmImguploadComponent } from './components/admin/adm-imgupload/adm-imgupload.component';
import { AdminGuard } from './components/admin/adm/adm.guard';
// import { AngularYandexMapsModule, IConfig } from 'angular8-yandex-maps';

import {AngularYandexMapsModule, YaConfig} from 'angular8-yandex-maps';

import { AdmModalSlideritemlinkComponent} from './components/admin/adm-modal-slideritemlink/adm-modal-slideritemlink.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  SvgCheckComponent,
  // SvgCheckComponentModule
} from './components/svg-check/svg-check.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BlockPartnersComponent } from './components/templates-common/block-partners/block-partners.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {
  SliderFullWidthComponent
} from './components/templates-common/slider-full-width/slider-full-width.component';

// const mapConfig: IConfig = {
const mapConfig: YaConfig = {
  apikey: '24cf6048-a890-43fc-bd24-ab3a8b2a287c',
  // lang: 'en_US',
  lang: 'ru_RU',
};

@NgModule({
  declarations: [
    AppComponent,
    AdmModalConfirmComponent,
    AdmModalEditComponent,
    AdmModalUploadComponent,
    AdmModalLinkComponent,
    AdmModalSlideritemlinkComponent,
    AdmImguploadComponent,
    // EngComponent,
    // TapComponent,
    // StartComponent,
    // OcsfoComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    AdminPanelComponent,
    AdminPanelNComponent,
    HeaderComponent,
    FooterComponent,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CommonModule,
    UploadComponent,
    AdmFileuploadComponent,
    SvgCheckComponent,
    // UploadComponentModule,
    // SvgCheckComponentModule,
    // AdmFileuploadComponentModule,
    CKEditorModule,
    AngularYandexMapsModule.forRoot(mapConfig),
    NgxSpinnerModule,
    BlockPartnersComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    BlockLinkSubscribeComponent,
    SidebarComponent,
    SliderFullWidthComponent,
  ],
  providers: [
    EventsService,
    AdminService,
    AdminGuard,
    Globals,
    Title,
    DataService,
    JQ
  ],
  exports: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
