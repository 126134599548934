import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth.service';
import { ProgressStatus, ProgressStatusEnum } from '../../../services/admin.service';

@Component({
  templateUrl: './adm-modal-edit.component.html'
})
export class AdmModalEditComponent implements OnInit {
  data: any;
  editForm: FormGroup;
  formFields: any[];
  formData: any;
  loading = false;
  submitted = false;
  title: string;
  isMedia = {};
  selectvariants = {}; // Объект для хранения набора данных в случае присутствия поля типа select

  modelname: string;
  schema: any;

  fileData: any;

  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showUploadError: boolean;

  public CKEConfig = {
    simpleUpload: {
      uploadUrl: this.dataService.srvurl + '/upload',
      headers: {
        Authorization: 'Bearer ' + this.auth.currentUserValue.jwt
      }
    }
  };

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public modal: NgbActiveModal,
  ) {
    this.schema = {};
    this.modelname = '';
    this.editForm = this.formBuilder.group({});
    this.formData = {};
    this.formFields = [];
  }

  get f() { return this.editForm.controls; }

  ngOnInit(): void {
    console.log ('schema', this.schema);
    console.log ('API_URL', this.dataService.srvurl);
    console.log ('modelname', this.modelname);
    const tmp = this.data.split(':');

    this.loading = true;
    if (this.modelname) {
      this.title = this.schema.title;
      this.dataService.getData(this.modelname, +tmp[3], {})
        .then(data => {
          // TODO ограничить ввод данных полями [name,title,icon]
          // Все остальные поля будут доступны для редактирования уже на странице самого экземпляра модели
          this.schema.fields.forEach(item => {
            if ([
              'name',
              'title',
              // 'icon',
              'url'].includes(item.name)){
              this.formData[item.name] = [data[item.name], item.required ? Validators.required : false];
              this.formFields.push(item);
            }
          });
          this.editForm = this.formBuilder.group(this.formData);

          console.log('formData', this.formData);
          console.log('formFields', this.formFields);

          this.loading = false;
        })
        .catch(err => console.error(err));
    }
    else{
      console.log('edit subItem');
      this.dataService.getData(tmp[0], +tmp[1], {})
        .then(async (data) => {
          this.formData = {};
// TODO 1. Определить тип изменяемого объекта (элемент вложенного списка или просто поле)
          console.log('this.schema.components[tmp[2]]', (this.schema.components && this.schema.components[tmp[2]]));
          // Если компонент, то формируем список полей исходя из полей схемы для нужного элемента
          const components = this.schema.components && this.schema.components[tmp[2]];

          if (components){
            this.title = components.title;
            const fields = (!components.single)
              ? data[tmp[2]].filter(item => +item.id === +tmp[3])[0]
              : (data[tmp[2]] || {});

            console.log('fields', fields);
            // TODO поля данных требую переработки.
            //  Если поле типа select или , то в него должно быть записано не полное значение, а значение поля id при наличии

            if(components.fields && components.fields.length > 0){
              for(const item of components.fields){
                if(item.type === 'select'){

                  fields[item.name] = (fields[item.name] && +fields[item.name].id) || null; // в соответствующем поле меняем значение на id имеющегося привязанного объекта

                  console.log('Нужно сформировать данные для списка по полю', item.name, 'из модели', item.model );
                  this.selectvariants[item.name] = await this.dataService.getData(item.model,false,{});
                }

                if(item.type === 'slider'){
                  fields[item.name] = (fields[item.name] && +fields[item.name].id) || null; // в соответствующем поле меняем значение на id имеющегося привязанного объекта
                }

                this.formData[item.name] = [fields[item.name], item.required ? Validators.required : false];
                this.formFields.push(item);
              }
            }

            components.imedia && Object.keys(components.imedia).forEach(key => {
              this.formData[key] = [fields[key], components.imedia[key].required ? Validators.required : false];
              components.imedia[key].name = key;
              this.formFields.push(components.imedia[key]);
            });
          }
          // add
          // else if (this.schema.component && this.schema.component[tmp[2]]) {
          //   this.title = this.schema.component[tmp[2]].title;
          //   const fields = data[tmp[2]];
          //   console.log('fields', fields);
          //   this.schema.component[tmp[2]].fields.forEach(item => {
          //     this.formData[item.name] = [fields[item.name], item.required ? Validators.required : false];
          //     this.formFields.push(item);
          //   });
          // }
          // end add
          else if (this.schema.imedia && this.schema.imedia[tmp[2]]){
            console.log('Это загрузка медиа, сынок!');
            this.schema.imedia[tmp[2]].name = tmp[2];
            this.formData[tmp[2]] = [(data[tmp[2]] || ''), this.schema.imedia[tmp[2]].required ? Validators.required : false];
            this.formFields.push(this.schema.imedia[tmp[2]]);
          }
          // else if (this.schema.files && this.schema.files[tmp[2]]){
          //   console.log('Это загрузка файла, сынок!');
          //   this.schema.files[tmp[2]].name = tmp[2];
          //   this.formData[tmp[2]] = [(data[tmp[2]] || ''), this.schema.files[tmp[2]].required ? Validators.required : false];
          //   this.formFields.push(this.schema.files[tmp[2]]);
          // }
          else{
            const field = this.schema.fields.filter(item => item.name === tmp[2]);
            if (field.length > 0){
              this.formData[tmp[2]] = [data[tmp[2]], field[0].required ? Validators.required : false];
              this.formFields.push(field[0]);
            }else{
              alert('Поле "' + tmp[2] + '" отсутствует в схеме данных. Обратитесь к разработчикам!');
            }
          }

          this.editForm = this.formBuilder.group(this.formData);
          console.log('formData', this.formData);
          console.log('formFields', this.formFields);
          this.loading = false;
        })
        .catch(err => console.error(err));

    }
    console.log('Incoming data', this.data);
  }

  onSubmit() {
    // console.log('onSubmit RUN');
    this.submitted = true;
    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    console.log('formData', this.formData);
    console.log('editForm', this.editForm.value);

    for (const key of Object.keys(this.editForm.value)){
      if (typeof this.editForm.value[key] === 'string'){
        this.editForm.value[key] = this.editForm.value[key].trim();
      }
    }
    console.log('editForm', this.editForm);
    this.modal.close(this.editForm.value);
  }

  onFileChange = () => {
    console.log('fileData changed', this.fileData[0]);
  }

  public onSubDataUpdate(data, fieldName){
    // console.log('data', data);
    // console.log(typeof data);
    // console.log('fieldName updated', fieldName);
    this.isMedia[fieldName] = true;
    this.editForm.value[fieldName] = data;
  }
}
